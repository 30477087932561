$(document).ready(function () {
  /*NAVBAR*/

  $("#mainNavbar").on("mouseleave", function () {
    $("#headerContactWrapper").addClass("d-none");
    $("#headerForclientWrapper").addClass("d-none");
  });

  $("#headerContactWrapperLink").on("mouseover", function () {
    $("#headerContactWrapper").removeClass("d-none");
    $("#headerForclientWrapper").addClass("d-none");
  });

  $("#headerContactWrapper").on("mouseleave", function () {
    $("#headerContactWrapper").addClass("d-none");
  });

  $("#headerForclientWrapperLink").on("mouseover", function () {
    $("#headerForclientWrapper").removeClass("d-none");
    $("#headerContactWrapper").addClass("d-none");
  });

  $("#headerForclientWrapper").on("mouseleave", function () {
    $("#headerForclientWrapper").addClass("d-none");
  });

  /*CALCULATOR*/

  var lastTotalCost = 0;
  var lastOrgHours = "???";

  $("#calcComputerVal").on("change", (e) => {
    let val = parseInt(e.target.value);
    if (val > 300) {
      val = 300;
      $("#calcComputerVal").val(val);
    }
    Calculate();
    $("#calcComputer").slider('value', val);
  })

  $("#calcOfficeVal").on("change", (e) => {
    let val = parseInt(e.target.value);
    if (val > 30) {
      val = 30;
      $("#calcOfficeVal").val(val);
    }
    Calculate();
    $("#calcOffice").slider('value', val);
  })

  $("#calcServerVal").on("change", (e) => {
    let val = parseInt(e.target.value);
    if (val > 10) {
      val = 10;
      $("#calcServerVal").val(val);
    }
    Calculate();
    $("#calcServer").slider('value', val);
  })

  $("#calc1cVal").on("change", (e) => {
    let val = parseInt(e.target.value);
    if (val > 30) {
      val = 30;
      $("#calc1cVal").val(val);
    }
    Calculate();
    $("#calc1c").slider('value', val);
  })

  $('#calcComputer').slider({
    range: 'min',
    max: 300,
    value: 5,
    slide: function (e, ui) {
      $('#calcComputerVal').val(ui.value);
      Calculate();
    }
  });
  $('#calcOffice').slider({
    range: 'min',
    max: 30,
    value: 1,
    slide: function (e, ui) {
      $('#calcOfficeVal').val(ui.value);
      Calculate();
    }
  });
  $('#calcServer').slider({
    range: 'min',
    max: 10,
    value: 0,
    slide: function (e, ui) {
      $('#calcServerVal').val(ui.value);
      Calculate();
    }
  });
  $('#calc1c').slider({
    range: 'min',
    max: 30,
    value: 0,
    slide: function (e, ui) {
      $('#calc1cVal').val(ui.value);
      Calculate();
    }
  });
  $("#calcWorkingHours3").on("change", (e)=>{
    Calculate();
  });
  $("#calcWorkingHours2").on("change", (e)=>{
    Calculate();
  });
  $("#calcWorkingHours1").on("change", (e)=>{
    Calculate();
  });

  function Calculate() {
    
    ym(33073363, 'reachGoal','calculate');
    
    var officies = parseInt($('#calcOfficeVal').val());
    var pc = parseInt($('#calcComputerVal').val());
    var base1c = parseInt($('#calc1cVal').val());
    
    var multiplier24 = +$('input[name=WorkingHours]:checked', '#calculator__form').val();
    switch(multiplier24){
      case 1:
        lastOrgHours = "8/5";
        break;
      case 1.1:
        lastOrgHours = "8/7";
        break;
      case 1.2:
        lastOrgHours = "24/7";
        break;
    }
    
    var onePCCost = 1400;
    var pcCost = onePCCost * pc;
    var serverCost = 3500 * $('#calcServerVal').val();
    var officeCost = officies > 1 ? (officies - 1) * 3000 : 0;
    var update1cCost = base1c * 1500;

    //Окончательная стоимость
    var costNumber = multiplier24 * (pcCost + serverCost + update1cCost + officeCost);
    if (costNumber < 10000) costNumber = 10000; //Минимальная стоимость
    var resultInvoice = costNumber - costNumber % 500; //Округляем сумму до 500 руб.
    lastTotalCost = resultInvoice;
    

    console.log("onePCPrice=" + onePCCost);
    console.log("pc=" + pcCost + " server=" + serverCost + " office=" + officeCost + " 1c=" + update1cCost + " 24/7=" + multiplier24);
    console.log("total=" + costNumber + " result=" + resultInvoice);

    //Количество заявок
    var requstsCost = 6306179 / 4786 // Стоимость заявки делим деньги на количество заявок. Будет меняться со временем.
    var calcRequsts = Math.round(costNumber / requstsCost);

    //Количество часов
    var hourCost = 925;
    var calcHours = Math.round(costNumber / hourCost);

    var calcDeparture = Math.round(0.568181818 + (0.000215909 * costNumber));

    var ratingFactor = 395 / 4918; // Количество заявок делим на количество оценок чтобы вычислить коэффициент
    var calcRatings = Math.round(calcRequsts * ratingFactor);

    if (resultInvoice)
      $('.calculator__cost-number').text(resultInvoice);

    if (calcHours >= 150) {
      $("#calcBonusDescription").removeClass("d-none");
    }
    else {
      $("#calcBonusDescription").addClass("d-none");
    }
    $('#calcRequsts').text(calcRequsts);
    $('#calcHours').text(calcHours);
    $('#calcDeparture').text(calcDeparture);
    $('#calcRatings').text(calcRatings);

    $('.calculator__cost-number').css('width', $('.calculator__cost-number').width());

    $('.calculator__cost-number, .calculator__parametr-value').counterUp({
      delay: 10,
      time: 1000
    })

    setTimeout(function () {
      $('.calculator__cost-number').css('width', '');
    }, 2500);

  };
  
  $("#sendMessage").on('click', (e) => {
    e.preventDefault();
    if(phoneIsValid){
      $.ajax({
        type: "POST",
        url: "https://alarm.goodadmins.api.qsolution.ru/new_client/admin",
        contentType: "application/json",
        beforeSend: function (xhr) {
          xhr.setRequestHeader('Authorization', 'Bearer e784bfe5-7c42-48d6-93f2-22fdbd386201');
        },
        data: JSON.stringify( { 
            phone: $('#calcPhone').val(),
            calculator: {
              pc: $('#calcComputerVal').val(),
              office: $('#calcOfficeVal').val(),
              server: $('#calcServerVal').val(),
              base1c: $('#calc1cVal').val(),
              hours: lastOrgHours,
              totalCost: lastTotalCost,
            }
        }),
        success: function(response) {
            if(response == 'OK') {
              $("#calcAccepted").removeClass("d-none");
            } else {
                alert("Ошибка при отправке сообщения.");
            }
        },
        error: function(response) {
          alert("Ошибка при отправке сообщения.");
        }
    });
    }
    
  });

/*MOBILE-MENU*/

  function menuToggle() {
    $('.nav').toggleClass('display-none-mobile');
    $('.nav__bar-icon').toggleClass('fa-bars').toggleClass('fa-times');
  }

  $('.nav__bar-icon').on('click', menuToggle);

  $('.nav__link').on('click', menuToggle);

/*CERTIFICATE-GALEREY*/

  $('.zoom-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    closeOnContentClick: false,
    closeBtnInside: false,
    mainClass: 'mfp-with-zoom mfp-img-mobile',
    image: {
      verticalFit: true,
      titleSrc: function(item) {
        return item.el.attr('title');
      }
    },
    gallery: {
      enabled: true
    },
    zoom: {
      enabled: true,
      duration: 300, // don't foget to change the duration also in CSS
      opener: function(element) {
        return element.find('img');
      }
    }

  });

});

jQuery(function($) {
  
  // Function which adds the 'animated' class to any '.animatable' in view
  var doAnimations = function() {
    
    // Calc current offset and get all animatables
    var offset = $(window).scrollTop() + $(window).height(),
        $animatables = $('.animatable');
    
    // Unbind scroll handler if we have no animatables
    if ($animatables.length == 0) {
      $(window).off('scroll', doAnimations);
    }
    
    // Check all animatables and animate them if necessary
		$animatables.each(function(i) {
       var $animatable = $(this);
			if (($animatable.offset().top + $animatable.height() - 20) < offset) {
        $animatable.removeClass('animatable').addClass('animated');
			}
    });

	};
  
  // Hook doAnimations on scroll, and trigger a scroll
	$(window).on('scroll', doAnimations);
  $(window).trigger('scroll');

});

/*Phone validation*/
let phoneIsValid = false;
const phoneInput = document.getElementById('calcPhone');
    phoneInput.addEventListener('input', function() {
      if(!validatePhoneNumber(phoneInput.value)){
        $("#phoneLabel").removeClass("d-none");
        phoneIsValid = false;
      }
      else{
        $("#phoneLabel").addClass("d-none");
        phoneIsValid=true;
      }
        if (!phoneInput.value.startsWith('+7')) {
          if(phoneInput.value.startsWith('+')){
            phoneInput.value= phoneInput.value.replace('+', '+7')
          }
          else{
            phoneInput.value = '+7' + phoneInput.value.replace(/^\+7/, '');
          }
        }
    });

    phoneInput.addEventListener('focus', function() {
        if (phoneInput.value === '+7') {
            phoneInput.setSelectionRange(2, 2);
        }
    });

    phoneInput.addEventListener('click', function(event) {
        if (phoneInput.selectionStart < 2) {
            phoneInput.setSelectionRange(2, 2);
            event.preventDefault();
        }
    });

function validatePhoneNumber(input) {
  var res =/^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$|^\+7\d{10}$/.test(input);
  return res;
}

