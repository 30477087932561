$(document).ready(function() {
  $(document).on('click', 'button[data-role="hire-button"]', function() {
    window.location.href = '#calculator';
  });
});
let apiQuery = {
  groupBy: "employee",
  value: "tasks",
  period: "month",
  categoryId: null
};

// Utils
const CHART_ITEM_COLORS = [
  "#68caff40",
  "#87b4ff40",
  "#8e75ff40",
  "#ff75753d",
  "#75e2ff3d",
  "#6cf9f03d",
  "#92f96c3d",
  "#e0f96c3d",
  "#ffd7553d",
  "#ffa0003d",
  "#c18de55c",
  "#c1f5f1"
];
const utils_getChartColor = (index) => {
  return CHART_ITEM_COLORS[index % CHART_ITEM_COLORS.length];
};
const CHART_ITEM_COLORS_BORDER = [
  "#68caff",
  "#87b4ff",
  "#8e75ff",
  "#ff7575",
  "#75e2ff",
  "#6cf9f0",
  "#92f96c",
  "#e0f96c",
  "#ffd755",
  "#ffa000",
  "#c18de5",
  "#4cfff1"
];
const utils_getChartColorBorder = (index) => {
  return CHART_ITEM_COLORS_BORDER[index % CHART_ITEM_COLORS_BORDER.length];
};

//

let buildQueryUrl = () => {
  let url = "https://goodadmins.api.qsolution.ru/site/statistics?";

  if (apiQuery.period != null) {
    url += `period=${apiQuery.period}`;
  }
  if (apiQuery.value != null) {
    url += `&value=${apiQuery.value}`;
  }
  if (apiQuery.groupBy != null) {
    url += `&group=${apiQuery.groupBy}`;
  }
  if (apiQuery.categoryId != null) {
    url += `&categoryid=${apiQuery.categoryId}`;
  }

  return url;
};
const roles = {
  1: "Основатель",
  2: "Специалист 1С",
  3: "Программист",
  5: "Старший системный администратор",
  20: "Системный администратор",
  24: "Системный администратор",
  25: "Системный администратор",
  27: "Системный администратор",
  28: "Системный администратор",
  29: "Программист",
  30: "Программист",
  31: "Программист",
};
const calc_valueProcent = (value, max) => {
  let procent = (value / max) * 95;
  return procent;
};

const effect_hideCardValues = () => {
  let items = $(".chart_item_no");
  for (let index = 0; index < items.length; index++) {
    items[index].style.opacity = `0`;
    items[index].classList.add("chart_item_del");
  }
};

const chart_delete_old_items = () => {
  $(".chart_item_del").remove();
};

const effect_showCardValues = async () => {
  let elems = $(".card_value_no");
  let labels = $(".card_value_label_no");
  let items = $(".chart_item_no");
  for (let index = 0; index < elems.length; index++) {
    let parseVal = Math.round(elems[index].dataset.w);
    elems[index].style.width = `${parseVal}%`;
    labels[index].style.left = `86%`;
    items[index].style.top = `${items[index].dataset.top}px`;
    items[index].style.opacity = `1`;
  }
};

const UPDATE_INTERVAL_MS = 100;
const CHART_ROOT_HEIGH_PX = 500;
const f_renderChartEmployees = (data) => {
  let maxValue = 0;
  for (let i = 0; i < data.length; i++) {
    if (data[i].Value > maxValue) {
      maxValue = data[i].Value;
    }
  }

  //get procent
  let appendBox = $("#chartBody");

  effect_hideCardValues();

  let rootHeight = 0;

  let i = 0;
  let interval = setInterval(() => {
    effect_showCardValues();
    if (data[i] !== undefined) {
      let color = utils_getChartColor(i);
      let bcolor = utils_getChartColorBorder(i);
      if (apiQuery.groupBy === "category") {
        appendBox.append(`<article data-top=${rootHeight} class="chart_item_no chart-item">
          <div class=" chart-item-head">
          <svg style="color: ${bcolor}" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class=" bi bi-tag" viewBox="0 0 16 16">
                            <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z"/>
                            <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z"/>
                          </svg>
          <div class="ps-2">
            <label>${data[i].Name}</label>
            <p>Категория</p>
          </div>
        </div>
        <div class="chart-item-body ">
          <span class="card_value_label card_value_label_no">${
            data[i].TextValue
          }</span>
          <div data-w="${calc_valueProcent(
            data[i].Value,
            maxValue
          )}" style="background: ${color}" class="card_value card_value_no"></div>
        </div>
      </article>`);
      } else if (apiQuery.groupBy === "employee") {
        let role="Сотрудник";
        if(data[i].GroupId in roles)
          role = roles[data[i].GroupId];
        appendBox.append(`<article data-empid=${
          data[i].GroupId
        } data-top=${rootHeight} class="employee_card chart_item_no chart-item">
          <div data-empid=${
            data[i].GroupId
          } class="position-relative card_emp_head chart-item-head">
          <img style="border: 2px solid ${bcolor};" src="images/employees/card${data[i].GroupId}.jpg" onerror="this.onerror=null;this.src='images/employees/default.png'"/>
            <div class="ps-2">
              <label>${data[i].Name}</label>
              <p>${role}</p>
            </div>
        </div>
        <div class="chart-item-body ">
          <span class="card_value_label card_value_label_no">${
            data[i].TextValue
          }</span>
          <div data-w="${calc_valueProcent(
            data[i].Value,
            maxValue
          )}" style="background: ${color}"
           class="card_value card_value_no"></div>
        </div>
      </article>`);
        let curId = data[i].GroupId;
        setTimeout(() => {
          let card = $(`.chart_view_card[data-empid=${curId}]`);
          if (card.length >= 1) {
            let copy = card[0].cloneNode(true);
            let find = $(`.card_emp_head[data-empid=${curId}]`);
            if (find.length > 0) {
              find[find.length - 1].prepend(copy);
            }
            console.log(find);
          }
        }, 5);
      }
    }

    rootHeight += 61;

    if (rootHeight < CHART_ROOT_HEIGH_PX) {
      appendBox[0].setAttribute("style", `height:${rootHeight}px`);
    }

    i++;

    if (i >= data.length) {
      clearInterval(interval);
      setTimeout(() => {
        effect_showCardValues();
        chart_delete_old_items();
        //f_appendEmployeeCards();
      }, UPDATE_INTERVAL_MS);
    }
  }, UPDATE_INTERVAL_MS);
};

//card_emp_head
$("#chartBody").on("mouseover", ".card_emp_head", (e) => {
  $(".chart_view_card ").addClass("d-none");
  let emp_row = e.target.closest("article");
  let el = emp_row.children[0].children[0];
  if (el.classList.contains("chart_view_card")) {
    el.classList.remove("d-none");
    
    //Позиционируем карточку
    const rect = emp_row.getBoundingClientRect();
    el.style.top = `${rect.top  + 51}px`;
    el.style.left = `${rect.left + 43}px`;
  }

  // здесь включаем подсказки на отображаемой карточке, так как при старте они скрыты и массово их не включить
  $(function () {
    $('[data-bs-toggle="tooltip"]').tooltip()
  })
});

$("#chartBody").on("mouseleave", ".card_emp_head", () => {
  $(".chart_view_card").addClass("d-none");
});

$(document).on("click", (e) => {
  if (!$(e.target).closest('.card_emp_head').length) {
    $(".chart_view_card").addClass("d-none");
  }
});
// categories scroll
let scrollPos = 0;
$("#chartBtnCategoryScrollTop").click(function () {
  var elem = $("#categoryBox");
  if (scrollPos > 0) {
    scrollPos -= 100;
  } else {
    scrollPos = 0;
  }

  if (scrollPos <= 0) {
    $("#chartBtnCategoryScrollTop").fadeOut();
  }

  $("#chartBtnCategoryScrollBottom").fadeIn();

  elem.animate({ scrollTop: scrollPos }, 500, "swing", function () {});
  return false;
});

$("#chartBtnCategoryScrollBottom").click(function () {
  var elem = $("#categoryBox");
  var height = $("#categoryWrapper").height();
  $("#chartBtnCategoryScrollTop").fadeIn();
  if (scrollPos < height) {
    scrollPos += 100;
    elem.animate({ scrollTop: scrollPos }, 500, "swing", function () {});
  }

  if (scrollPos >= height) {
    $("#chartBtnCategoryScrollBottom").fadeOut();
  }
});

const effect_showCategories = () => {
  let elems = $(".category_anim_no");
  let i = 0;
  let interv = setInterval(() => {
    elems[i].style.opacity = 1;
    i++;
    if (i >= elems.length) {
      clearInterval(interv);
    }
  }, 30);
};

const CATEGORIES_PAGE_SIZE = 20;
const f_renderCategoryButtons = (data) => {
  let wrapper = $("#categoryWrapper");
  $("#categoryWrapper button").fadeOut();

  setTimeout(() => {
    $("#categoryWrapper button").remove();
    for (let i = 0; i < data.length; i++) {
      wrapper.append(
        ` <button data-catid=${data[i].Id} class="chart_category_btn category_anim_no btn-eff-opacity chart-btn-category button">${data[i].Name}</button>`
      );
    }

    effect_showCategories();
  }, 500);
};

let withActiveCategory = false;

const f_updateChart = () => {
  console.log("update");
  $.ajax({
    type: "GET",
    url: buildQueryUrl(),
    contentType: "application/json; charset=UTF-8",
    success: function (response) {
      f_renderChartEmployees(response.Data);

      console.log(withActiveCategory);
      if (withActiveCategory == false) {
        f_renderCategoryButtons(response.AvailableCategories);
      }
    },
    statusCode: {
      400: function () {
        alert("400");
      }
    }
  });
};

// category
$("#categoryWrapper").on("click", ".chart_category_btn", (e) => {
  let btn = e.target;
  if (btn.classList.contains("picked")) {
    apiQuery.categoryId = null;
    withActiveCategory = false;
    btn.classList.remove("picked");
  } else {
    $(".chart_category_btn").removeClass("picked");

    if (btn.dataset.catid != undefined) {
      btn.classList.add("picked");
      apiQuery.categoryId = btn.dataset.catid;
      withActiveCategory = true;
    } else {
      apiQuery.categoryId = null;
      withActiveCategory = false;
    }
  }

  f_updateChart();
});

// group by
$(".query_group_by").on("click", (e) => {
  $(".query_group_by").removeClass("picked");
  e.target.classList.add("picked");
  apiQuery.groupBy = e.target.dataset.value;
  f_updateChart();
});

// value
$(".query_value").on("click", (e) => {
  $(".query_value").removeClass("picked");
  e.target.classList.add("picked");
  apiQuery.value = e.target.dataset.value;
  f_updateChart();
});

// period
$(".query_period").on("click", (e) => {
  $(".query_period").removeClass("picked");
  e.target.classList.add("picked");
  apiQuery.period = e.target.dataset.value;
  f_updateChart();
});

f_updateChart();
